<template>
	<Dialog ref="dialog"  :width="800">
		<template v-slot:title="{}">
			<div style="min-height: 25px;">
				<v-row no-gutters justify="center">
					<v-col cols="8">
						<template v-if="isScanning">
							<Title centered>{{$t('Receipts.Scanning')}}</Title>
							<v-progress-linear indeterminate></v-progress-linear>
						</template>
					</v-col>
				</v-row>
			</div>
		</template>

		<v-container fluid>
			<v-row dense>
				<v-col cols="12">
					<template v-if="file && file.srcDataContent">
						<iframe v-if="file.type === 'application/pdf'" :src="`${file.srcDataContent}#toolbar=0&navpanes=0`" width="100%" height="100%" style="min-height: 500px; border: 1 px solid black;"/>

						<v-sheet v-else max-height="405" style="overflow: auto;">
							<v-img contain :src="file.srcDataContent" max-height="500" width="100%"/>
						</v-sheet>
					</template>
				</v-col>
				<v-col cols="12">
					<v-container fluid>
						<v-form ref="form" :disabled="isScanning">
							<v-row dense>
								<v-col cols="12">
									<InputField autofocus name="Beskrivning" ref="Beskrivning" :model="model"/>
								</v-col>
								<v-col cols="12" md="6">
									<DatePicker name="Datum" ref="Datum" :model="model"/>
								</v-col>
								<v-col cols="12" md="6">
									<SelectField name="Varugrupp" ref="Varugrupp" :model="model" :items="Varugrupper" item-value="Namn" item-text="Namn" clearable/>
								</v-col>
								<v-col cols="12" md="6">
									<NumberField name="Summa" ref="Summa" :model="model" suppress-zeros/>
								</v-col>
								<v-col cols="12" md="6" v-if="FtgInfo.Momspliktig">
									<NumberField name="Moms" ref="Moms" :model="model" suppress-zeros/>
								</v-col>
								<v-col cols="12" md="6">
									<InfiniteSelect :label="$t('ValutaKod')" v-model="model.ValutaKod" name="ValutaKod" ref="ValutaKod" item-value="Valutakod" item-text="Valuta" :items="Valutor">
										<template v-slot:append="{value}">
											<span class="mt-1">{{value}}</span>
										</template>

										<template v-slot:itemtitle="{item, text}">
											<v-row no-gutters>
												<v-col cols="8" class="text-truncate">
													<v-img v-if="item.Landskod && item.Landskod !== 'XX'" :src="`/countryflags/${item.Landskod.toLowerCase()}.png`" :lazy-src="`/countryflags/${item.Landskod.toLowerCase()}.png`" contain width="25" style="display: inline-block; vertical-align: middle"/>
													{{text}}
												</v-col>
												<v-col cols="4" class="text-right">
													{{item.Valutakod}}
												</v-col>
											</v-row>
										</template>
									</InfiniteSelect>
								</v-col>
							</v-row>
						</v-form>

						<v-row dense>
								<v-col cols="12" md="7">
									<SimpleCheckbox v-model="useAutoScan" :label="$t('AutoScanTitle')" ref="UseAutoScan" name="UseAutoScan" @change="onSetUseAutoScan" hide-details/>
								</v-col>
							</v-row>

						</v-container>
				</v-col>
			</v-row>
		</v-container>

		<template v-slot:buttons="{hide}">
			<v-btn color="secondary" @click="hide">{{$t('Cancel')}}</v-btn>
			<v-spacer/>
			<v-btn color="primary" ref="SubmitBtn" :disabled="isScanning" @click="onSubmit">{{$t('Confirm')}}</v-btn>
		</template>
	</Dialog>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import BaseModel              from '../models/BaseModel.js';
import {$t}                   from '../plugins/i18n.js';
import Validate               from '../lib/Validate.js';
import LocalStorage           from '../lib/LocalStorage.js';
import Dialog                 from './Dialog.vue';
import DatePicker             from './form/DatePicker.vue';
import NumberField            from './form/NumberField.vue';
import SelectField            from './form/SelectField.vue';
import InfiniteSelect         from './InfiniteSelect.vue';
import Title                  from './typography/Title.vue';
import InputField             from './form/InputField.vue';
import SimpleCheckbox         from './SimpleCheckbox.vue';

class ReceiptResultModel extends BaseModel {
	static get propertyMap() {
		return {
			Datum: {
				label:        $t('Date'),
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.validDate,
				],
				isDateField: true,
			},

			ValutaKod: {
				label:        $t('ValutaKod'),
				type:         String,
				defaultValue: '',
				rules:        [],
			},

			Summa: {
				label:        $t('Summa'),
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			Moms: {
				label:        $t('Moms'),
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			Varugrupp: {
				label:        $t('Varugrupp'),
				type:         String,
				defaultValue: '',
				rules:        [],
			},

			Beskrivning: {
				label:        $t('Description'),
				type:         String,
				defaultValue: '',
				rules:        [],
			},
		};
	}
}

export default {
	name:       'ReceiptScanDialog',
	components: {SimpleCheckbox, InputField, Title, InfiniteSelect, SelectField, NumberField, DatePicker, Dialog},

	data: () => ({
		isScanning:       false,
		useAutoScan:      LocalStorage.get('AUTO_SCAN_FILES', true),
		file:             null,
		model:            new ReceiptResultModel(),
		traktamenteLista: null,
		tabOrder:         [
			'Beskrivning',
			'Datum',
			'Varugrupp',
			'Summa',
			'Moms',
			'ValutaKod',
			'SubmitBtn',
		],
	}),

	methods: {
		...mapActions(['loadVarugrupper']),

		onSetUseAutoScan() {
			LocalStorage.set('AUTO_SCAN_FILES', this.useAutoScan);
		},

		scan(file) {
			this.loadTraktamenteLista();
			this.loadVarugrupper();

			this.model = new ReceiptResultModel();
			this.file = file;

			this.$tryCatch({
				task: async () => {
					this.isScanning = true;
					this.$refs.dialog.show();

					const scanResult = await this.$http.post('/receipts/scan', {
						Content: this.file.content,
						Type:    this.file.type,
					});

					const {
						TransactionDate,
						Total,
						Tax,
						ReceiptTypeCronaStdText,
						Currency,
						MerchantName,
					} = scanResult;

					this.model = new ReceiptResultModel({
						Datum:       TransactionDate,
						Summa:       Total,
						Moms:        Tax,
						Varugrupp:   this.Varugrupper.find(({Namn}) => Namn === ReceiptTypeCronaStdText) ? ReceiptTypeCronaStdText : '',
						ValutaKod:   this.Valutor.find(({Valutakod}) => Valutakod === Currency) ? Currency : 'SEK',
						Beskrivning: MerchantName,
					});
				},

				finally: () => {
					this.isScanning = false;
					setTimeout(() => {
						this.$refs.Beskrivning.focus();
					}, 100);
				},
			});
		},

		loadTraktamenteLista() {
			this.$tryCatch({
				task: async () => {
					this.traktamenteLista = this.traktamenteLista || await this.$http.get(`/traktamente/${encodeURIComponent(this.SalaryYear)}`, {
						params: {
							fields: ['Land', 'Landskod', 'Valuta', 'Valutakod'],
						},
					});
				},
			});
		},

		onSubmit() {
			if(!this.$validateForm()) {
				return;
			}

			this.$emit('submit', this.model.toJSON());
			this.$refs.dialog.hide();
		},
	},

	computed: {
		...mapState(['FtgInfo', 'Varugrupper', 'SalaryYear']),

		Valutor() {
			return [
				{Land: 'Sverige', Landskod: 'se', Valutakod: 'SEK', Valuta: 'Svenska kronor', divideAfter: true},
				...(this.traktamenteLista || []),
			];
		},
	},
}
</script>

<style scoped>

</style>
